/* eslint-disable react/no-unescaped-entities */

/**
 * @file Terms of Service
 * @author Alwyn Tan
 */

import React from 'react'
import { Layout } from 'react-feather'
import styled from 'styled-components'
import logo from '../images/logo.png'

const FullScreenContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  padding: 200px 20px 100px 20px;
  max-width: 450px;

  h3 {
    padding-bottom: 2px;
  }

  p {
    font-size: 1.1em;
    padding-bottom: 20px;
  }
`

const Logo = styled.img`
  height: 50px;
  object-fit: contain;
  margin-bottom: 20px;
`

const Terms = () => {
  return (
    <Layout hideHeader>
      <FullScreenContainer>
        <Container>
          <a href="/">
            <Logo src={logo} />
          </a>
          <h2 style={{ paddingBottom: 20 }}>Terms of Service</h2>
          <h3 style={{ paddingBottom: 20 }}>Hey! Welcome to Solo!</h3>
          <p>
            Solo builds technologies and services that enable people to connect
            with each other, businesses, and build communities. These Terms
            govern your use of Solo and the other products, features, apps,
            services, technologies, and software we offer, except where we
            expressly state that separate terms (and not these) apply. These
            Products are provided to you by Solo, Inc.
          </p>
          <p>
            We don’t charge you to use Solo or the other products and services
            covered by these Terms. Instead, businesses and organizations pay us
            to show you ads for their products and services. By using our
            Products, you agree that we can show you ads that we think will be
            relevant to you and your interests. We use your personal data to
            help determine which ads to show you.
          </p>
          <p>
            We don’t sell your personal data to advertisers, and we don’t share
            information that directly identifies you (such as your name, email
            address or other contact information) with advertisers unless you
            give us specific permission. Instead, advertisers can tell us things
            like the kind of audience they want to see their ads, and we show
            those ads to people who may be interested. We provide advertisers
            with reports about the performance of their ads that help them
            understand how people are interacting with their content.
          </p>
          <p>
            Our Data Policy explains how we collect and use your personal data
            to determine some of the ads you see and provide all of the other
            services described below. You can also go to your settings at any
            time to review the privacy choices you have about how we use your
            data.
          </p>
          <h3>How our services are funded</h3>
          <p>
            Instead of paying to use Solo and the other products and services we
            offer, by using the Solo Products covered by these Terms, you agree
            that we can show you ads that businesses and organizations pay us to
            promote on and off the Company Products. We use your personal data,
            such as information about your activity and interests, to show you
            ads that are more relevant to you.
          </p>
          <p>
            Protecting people's privacy is central to how we've designed our ad
            system. This means that we can show you relevant and useful ads
            without telling advertisers who you are. We don't sell your personal
            data. We allow advertisers to tell us things like their business
            goal, and the kind of audience they want to see their ads (for
            example, people between the age of 18-35 who like Greek food). We
            then show their ad to people who might be interested.
          </p>
          <p>
            We collect and use your personal data in order to provide the
            services described above to you. You can learn about how we collect
            and use your data in our Data Policy. You have controls over the
            types of ads and advertisers you see, and the types of information
            we use to determine which ads we show you.
          </p>
          <h3>Limits on liability</h3>
          <p>
            We work hard to provide the best Products we can and to specify
            clear guidelines for everyone who uses them. Our Products, however,
            are provided "as is," and we make no guarantees that they always
            will be safe, secure, or error-free, or that they will function
            without disruptions, delays, or imperfections. To the extent
            permitted by law, we also DISCLAIM ALL WARRANTIES, WHETHER EXPRESS
            OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. We do
            not control or direct what people and others do or say, and we are
            not responsible for their actions or conduct (whether online or
            offline) or any content they share (including offensive,
            inappropriate, obscene, unlawful, and other objectionable content).
            We cannot predict when issues might arise with our Products.
            Accordingly, our liability shall be limited to the fullest extent
            permitted by applicable law, and under no circumstance will we be
            liable to you for any lost profits, revenues, information, or data,
            or consequential, special, indirect, exemplary, punitive, or
            incidental damages arising out of or related to these Terms or the
            Products, even if we have been advised of the possibility of such
            damages. Our aggregate liability arising out of or relating to these
            Terms or the Products will not exceed the greater of $100 or the
            amount you have paid us in the past twelve months.
          </p>
          <h3>DATA POLICY</h3>
          <h3>What kinds of information do we collect?</h3>
          <p>
            To provide the Products, we must process information about you. The
            types of information we collect depend on how you use our Products.
            You can learn how to access and delete information we collect by
            emailing info@soloclub.app.
          </p>
          <h3>Things you and others do and provide</h3>
          <p>
            Information and content you provide. We collect the content,
            communications and other information you provide when you use our
            Products, including when you sign up for an account, create or share
            content, and message or communicate with others. This can include
            information in or about the content you provide (like metadata),
            such as the location of a photo or the date a file was created. It
            can also include what you see through features we provide, such as
            our camera, so we can do things like suggest masks and filters that
            you might like, or give you tips on using camera formats. Our
            systems automatically process content and communications you and
            others provide to analyze context and what's in them for the
            purposes described below. Learn more about how you can control who
            can see the things you share.
          </p>
          <h3>Data with special protections</h3>
          <p>
            You can choose to provide information in your Solo profile fields
            about your religious views, political views, who you are "interested
            in," or your health. This and other information (such as racial or
            ethnic origin, philosophical beliefs or trade union membership)
            could be subject to special protections under the laws of your
            country.
          </p>
          <h3>Networks and connections</h3>
          <p>
            We collect information about the people, Pages, accounts, hashtags
            and groups you are connected to and how you interact with them
            across our Products, such as people you communicate with the most or
            groups you are part of. We also collect contact information if you
            choose to upload, sync or import it from a device (such as an
            address book or call log or SMS log history), which we use for
            things like helping you and others find people you may know and for
            the other purposes listed below.
          </p>
          <h3>Your usage</h3>
          <p>
            We collect information about how you use our Products, such as the
            types of content you view or engage with; the features you use; the
            actions you take; the people or accounts you interact with; and the
            time, frequency and duration of your activities. For example, we log
            when you're using and have last used our Products, and what posts,
            videos and other content you view on our Products. We also collect
            information about how you use features like our camera.
          </p>
        </Container>
      </FullScreenContainer>
    </Layout>
  )
}

export default Terms
